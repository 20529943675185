import React, { useEffect, useRef } from "react"
import { useInView } from "react-intersection-observer"
import { motion, isValidMotionProp, useAnimation } from "framer-motion"
import { forwardRef, Box } from "@chakra-ui/react"

export default function CreateMotionComponent(Component: any) {
  return motion.div(
    forwardRef((props, ref) => {
      const inProps = Object.fromEntries(
        Object.entries(props).filter(([key]) => !isValidMotionProp(key))
      )
      return <Component ref={ref} {...inProps} />
    })
  )
}

export const FadeUpBox = ({
  children,
  delay = 0,
}: {
  children: React.ReactChildren
  delay?: number
}) => {
  const played = useRef(false)
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (inView && !played.current) {
      controls.start("visible")
      played.current = true
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.8, delay }}
      variants={{
        visible: {
          opacity: 1,
          y: 0,
        },
        hidden: { opacity: 0, y: 100 },
      }}
    >
      {children}
    </motion.div>
  )
}

export const SlideInBox = ({
  children,
  delay = 0,
}: {
  children: React.ReactChildren
  delay?: number
}) => {
  const played = useRef(false)
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.1,
  })

  useEffect(() => {
    if (inView && !played.current) {
      controls.start("visible")
      played.current = true
    }
  }, [controls, inView])

  return (
    <Box ref={ref}>
      <motion.div
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.6, delay }}
        variants={{
          visible: { width: "100%" },
          hidden: { width: "0" },
        }}
      >
        {children}
      </motion.div>
    </Box>
  )
}

export const FadeIn: React.FC = ({ children }) => {
  const played = useRef(false)
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.1,
  })

  useEffect(() => {
    if (inView && !played.current) {
      controls.start("visible")
      played.current = true
    }
  }, [controls, inView])

  return (
    <Box ref={ref}>
      <motion.div
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.6 }}
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 0.97 },
        }}
      >
        {children}
      </motion.div>
    </Box>
  )
}

export const Slide = ({
  children,
  vertical,
  duration,
}: {
  children: React.ReactChildren
  vertical?: boolean
  duration?: number
}) => {
  const played = useRef(false)
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (inView && !played.current) {
      controls.start("visible")
      played.current = true
    }
  }, [controls, inView])

  const h = {
    visible: { width: "100%" },
    hidden: { width: "0%" },
  }

  const v = {
    visible: { height: "100%" },
    hidden: { height: "0%" },
  }

  return (
    <Box ref={ref}>
      <motion.div
        animate={controls}
        initial="hidden"
        transition={{ duration }}
        variants={vertical ? v : h}
      >
        {children}
      </motion.div>
    </Box>
  )
}
