import React from "react"
import { Box, Flex, Text, Image, useColorModeValue } from "@chakra-ui/react"
import { FadeIn } from "~components/shared/custom-animation"

const ProductList: React.FC<{
  number: number
  imgUrl: string
  marginTop: string
  imgWidth: string
  title: string
}> = ({ number, imgUrl, marginTop, imgWidth, title, children }) => {
  const textColor = useColorModeValue("#171f2b", "white")

  return (
    <FadeIn>
      <Flex justifyContent="center" h="600px">
        <Text
          fontSize="300px"
          fontFamily="serif"
          color={textColor}
          lineHeight="100px"
          position="absolute"
          mr={["150px", "150px", "150px", "150px", "650px"]}
        >
          {number}
        </Text>
        <Image
          src={imgUrl}
          w={"auto"}
          maxW={imgWidth}
          position="absolute"
          maxH={"500px"}
        />
        <Box
          position="relative"
          top="150px"
          ml={["0", "0", "0", "0", "800px"]}
          mt={[marginTop, marginTop, marginTop, marginTop, 0]}
          fontSize="50px"
          w="min-content"
          align="center"
        >
          <Text w="max-content" fontSize={["lg", "lg", "lg", "xl"]}>
            {title}
          </Text>
          <Text fontSize="20px" color={textColor} w="250px">
            {children}
          </Text>
        </Box>
      </Flex>
    </FadeIn>
  )
}

export default ProductList
