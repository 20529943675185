import React, { useState } from "react"
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  Input,
  useToast,
} from "@chakra-ui/react"
import firebase from "gatsby-plugin-firebase"

const HeaderModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useToast()

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const initialRef = React.useRef<HTMLInputElement | null>(null)

  var db = firebase.firestore()
  function addEmail() {
    db.collection("newsletter")
      .doc(email)
      .set({ name: name, email: email })
      .then((docRef) => {})
      .catch((error) => {})
  }

  return (
    <Box mt="30px">
      <Button onClick={onOpen} bg="#7de3ff" color="black">
        <b>
          <i>Join the waitlist</i>
        </b>
      </Button>

      <Modal isOpen={isOpen} size={"lg"} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box w="100%" align="center">
              <Text fontSize="24px">Join the waitlist</Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <Input
                ref={initialRef}
                placeholder="Name"
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>

            <FormControl mt={6}>
              <Input
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="#7de3ff"
              color="black"
              mr={3}
              onClick={() => {
                addEmail()
                toast({
                  title: "Email added.",
                  description:
                    "Your email has been successfully added to the waitlist.",
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                })
              }}
            >
              JOIN
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default HeaderModal
