import React, { useState, useEffect } from "react"
import {
  Box,
  Heading,
  Text,
  Button,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react"
import firebase from "gatsby-plugin-firebase"
import ResponsiveBlock from "../shared/responsive-block"
import { navigate } from "gatsby"

const RecentBlogs = () => {
  const [blogs, setBlogs] = useState([])

  const textColor = useColorModeValue("#171F2B", "white")

  useEffect(() => {
    getBlogs()
  }, [])

  var db = firebase.firestore()

  async function getBlogs() {
    const query = await db
      .collection("Blogs")
      .orderBy("date", "desc")
      .limit(3)
      .get()

    const tmpBlogs: any = []

    query.forEach((doc) => tmpBlogs.push(doc.data()))

    setBlogs(tmpBlogs)
  }

  const blogsMap = (blogs: any) => {
    return blogs.map((blog: any) => {
      return (
        <ResponsiveBlock>
          <Box
            my={6}
            align="left"
            border="1px solid rgba(170,170,170,0.4)"
            borderRadius="3xl"
            p={4}
          >
            <Heading color={textColor} fontSize="20px" mb={2}>
              {blog.title}
            </Heading>
            <Flex justify="space-between">
              <Text mb={5} fontSize="16px" color="gray.400">
                by: {blog.author}
              </Text>
              <Text>{blog.date}</Text>
            </Flex>
            <Text fontSize="14px">{blog.content.slice(0, 200)}...</Text>
            <Button
              mt={4}
              variant="outline"
              size="sm"
              onClick={() => navigate("/blog/" + blog.id)}
            >
              Read More
            </Button>
          </Box>
        </ResponsiveBlock>
      )
    })
  }

  return (
    <Box align="center" my={20}>
      <Box>
        <Text fontSize="50px" fontWeight={700}>
          What We're Up To
        </Text>
      </Box>
      {blogsMap(blogs)}
    </Box>
  )
}

export default RecentBlogs
