import React from "react"

import { Box } from "@chakra-ui/react"
import LandingPage from "~components/home/landing-page"

const IndexPage = () => {
  return (
    <Box>
      <LandingPage />
    </Box>
  )
}

export default IndexPage
