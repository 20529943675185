import React from "react"
import { Box, Text, useColorModeValue } from "@chakra-ui/react"

const Download = () => {
  const bgColor = useColorModeValue("white", "#171F2B")
  const textColor = useColorModeValue("#171F2B", "white")

  return (
    <Box>
      <Box
        backgroundColor={"#f26d3c"}
        w="100%"
        h="100vh"
        align="center"
        pt="200px"
      >
        <Text color={textColor} fontSize="40px">
          Stop missing out on the Crypto hype.
        </Text>
        <Text color={textColor} fontSize="15px">
          Start small with Cypher and watch your spare change grow. With Cypher,
          you'll invest a little bit each day, so you don't have to worry about
          getting in at the right time.
        </Text>
        <Box
          as="button"
          height="40px"
          lineHeight="1.2"
          transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
          px="30px"
          borderRadius="25px"
          fontSize="14px"
          fontWeight="bold"
          bg={bgColor}
          color={textColor}
          mt="50px"
        >
          Download Cypher today and be a part of the action!
        </Box>
      </Box>
    </Box>
  )
}

export default Download
