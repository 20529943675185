import React from "react"
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react"
import { FadeIn } from "~components/shared/custom-animation"
import ResponsiveBlock from "~components/shared/responsive-block"

const Media = () => {
  const bgColor = useColorModeValue("white", "#171F2B")
  const textColor = useColorModeValue("#171F2B", "white")

  return (
    <Flex direction="column" h={"60vh"} align="center" justify="center">
      <FadeIn>
        <Flex backgroundColor={bgColor} w="100%">
          <FadeIn>
            <Box w={"100%"}>
              <Text fontSize="60px" color={textColor}>
                Cypher's Round Up Feature
              </Text>
              <ResponsiveBlock>
                <Text fontSize="20px" color={textColor}>
                  Cypher rounds up your everyday transactions to the nearest
                  dollar and puts that spare change into whichever
                  cryptocurrencies you choose to invest in.
                </Text>
              </ResponsiveBlock>
            </Box>
          </FadeIn>
        </Flex>
      </FadeIn>
    </Flex>
  )
}

export default Media
