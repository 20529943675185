import React from "react"
import { Box, Text, SimpleGrid, useColorModeValue } from "@chakra-ui/react"

import Product1 from "~images/sm-1.png"
import Product2 from "~images/sm-2.png"
import Product3 from "~images/sm-3.png"
import ProductList from "~components/home/product-list"
import Header from "~components/home/header"
import Media from "~components/home/media"
import Download from "~components/home/download"
import RecentBlogs from "~components/home/recent-blogs"

const LandingPage: React.FC = () => {
  const bgColor = useColorModeValue("white", "#171F2B")
  const textColor = useColorModeValue("#171F2B", "white")

  return (
    <Box
      backgroundColor={bgColor}
      // w={["960px", "100%"]}
      align="center"
      w={"100%"}
      m={0}
    >
      <Header />
      <Media />
      <Box h="100%">
        <Text align="center" pt={40} fontSize="60px" color={textColor}>
          How it Works.
        </Text>
        <SimpleGrid columns={1} align="center" pt="100px" gridGap="300px">
          <ProductList
            number={1}
            imgUrl={Product1}
            marginTop="300px"
            imgWidth="500px"
            title={"Pick Your Coins"}
          >
            Make an account with Cypher and choose which cryptocurrencies your
            spare change should be invested into.{" "}
          </ProductList>
          <ProductList
            number={2}
            imgUrl={Product2}
            marginTop="400px"
            imgWidth="300px"
            title={"Start Rounding Up"}
          >
            Use your card to make a purchase. For example, a $2.43 purchase will
            Round-Up 57 cents.
          </ProductList>
          <ProductList
            number={3}
            imgUrl={Product3}
            marginTop="200px"
            imgWidth="500px"
            title={"Earn Crypto"}
          >
            Once your round-ups accumulate and reach $5, your change is invested
            automatically into any of the cryptocurrencies you have selected.
          </ProductList>
        </SimpleGrid>
      </Box>
      <RecentBlogs />
      <Download />
    </Box>
  )
}

export default LandingPage
