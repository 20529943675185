import React from "react"
import { Box, Flex, Text, Image, useColorModeValue } from "@chakra-ui/react"
import { FadeIn } from "~components/shared/custom-animation"
import ResponsiveBlock from "~components/shared/responsive-block"
import HeaderMockup from "~images/header-mockup.png"
import { motion } from "framer-motion"
import HeaderModal from "~components/home/header-modal"

const Header = () => {
  const textColor = useColorModeValue("#171F2B", "white")

  return (
    <Flex w="100%" h={"calc(90vh - 80px)"} pt={[60, 0]}>
      <ResponsiveBlock
        w={"100%"}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          justify="space-evenly"
          flexDirection={["column", "row"]}
          gridGap="40px"
        >
          <Box align="center" w="100%" maxWidth="500px">
            <Text
              color={textColor}
              fontSize="44px"
              align="center"
              fontWeight={700}
            >
              Purchase Bitcoin, Ethereum, and other alt-coins using your spare
              change
            </Text>
            <Box mt="40px">
              <Text color={textColor} fontSize="20px" align="center">
                Invest in cryptocurrency directly and automatically through
                Cypher's round-up feature
              </Text>
            </Box>
            <HeaderModal />
          </Box>
          <FadeIn>
            <motion.div
              style={{ width: "auto", height: "100%" }}
              animate={{ y: 20 }}
              transition={{
                ease: "easeInOut",
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <Image src={HeaderMockup} maxH="600px" />
            </motion.div>
          </FadeIn>
        </Flex>
      </ResponsiveBlock>
    </Flex>
  )
}

export default Header
